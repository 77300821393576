import { Classrooms } from "~/classes/models/classrooms/classroom.model";

export const useActiveClassrooms = (classrooms: Classrooms): Classrooms => {
  return classrooms
    .filter((classroom) => {
      return classroom.isArchived == undefined || classroom.isArchived == false;
    })
    .sort((a, b) => {
      if (a.displayOrder == b.displayOrder) {
        return (a.label ?? a.name ?? "").localeCompare(b.label ?? b.name ?? "");
      }

      return (a.displayOrder ?? 0) - (b.displayOrder ?? 0);
    });
};
